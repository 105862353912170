import { graphql } from "gatsby";
import React from "react";
import AboutUsBanner from "../components/AboutUsPage/AboutUsBanner";
import FounderLetterSection from "../components/AboutUsPage/FounderLetterSection";
import MapSection from "../components/AboutUsPage/MapSection";
import OurInvestorsSection from "../components/AboutUsPage/OurInvestorsSection";
import OurMissionSection from "../components/AboutUsPage/OurMissionSection";
import OurValuesSection from "../components/AboutUsPage/OurValuesSection";
import BottomCTA from "../components/BottomCta";
import { NavigationBarVariant } from "../components/NavigationBar/models";
import SEO from "../components/Seo";
import Layout from "../containers/Layout";
import teamphoto from "../images/teamphoto.png";
import { nav } from "../utils/navigation";

// CTA: window.open('https://jobs.lever.co/perpetualabs.com', '_blank')

interface AboutPageProps {
  data: any;
}

const AboutPage: React.FC<AboutPageProps> = ({ data }) => {
  const aboutpages = data.allContentfulAboutPage.edges;
  const currentAboutPage = aboutpages && aboutpages[0].node;

  return (
    <Layout navigationBarVariant={NavigationBarVariant.light}>
      <SEO
        title={currentAboutPage?.seoSettings?.metadataTitle}
        description={currentAboutPage?.seoSettings?.metadataDescription}
        image={currentAboutPage?.seoSettings?.openGraphImage?.url}
        canonical={currentAboutPage?.seoSettings?.canonicalUrl}
        robots={currentAboutPage?.seoSettings?.robots}
      />
      <AboutUsBanner
        title={currentAboutPage?.bannerTitle}
        subtitle={currentAboutPage?.bannerSubtitle}
        style={{
          backgroundImage: `linear-gradient(286deg, rgba(66, 165, 245, 0.8), rgba(130, 65, 243, 0.8)), url("${teamphoto}")`,
          height: `390px`
        }}
        src={teamphoto}
        gradientOverlay={true}
      />
      <FounderLetterSection
        title={currentAboutPage?.founderLetterTitle}
        body={currentAboutPage?.founderLetterBody?.founderLetterBody}
        signature={currentAboutPage?.founderLetterSignature?.gatsbyImageData}
      />
      <OurMissionSection
        title={currentAboutPage?.missionSectionTitle}
        description={currentAboutPage?.missionSectionDescription}
        boxes={currentAboutPage?.missionSectionBoxes}
      />
      <OurValuesSection
        title={currentAboutPage?.valuesSectionTitle}
        values={currentAboutPage?.valuesSectionValues}
      />
      <OurInvestorsSection title={currentAboutPage?.investorSectionTitle} />
      <MapSection offices={currentAboutPage?.offices} />
      <BottomCTA
        title={currentAboutPage?.bottomCta?.title}
        subtitle={currentAboutPage?.bottomCta?.subtitle}
        primaryButtonText={currentAboutPage?.bottomCta?.primaryButtonText}
        primaryButtonClassName={
          currentAboutPage?.bottomCta?.primaryButtonClassName
        }
        secondaryButtonClassName={
          currentAboutPage?.bottomCta?.secondaryButtonClassName
        }
        secondaryButtonText={currentAboutPage?.bottomCta?.secondaryButtonText}
        primaryButtonOnClick={() => {
          nav(currentAboutPage?.bottomCta?.primaryButtonRoute);
        }}
        secondaryButtonOnClick={() =>
          nav(currentAboutPage?.bottomCta?.secondaryButtonRoute)
        }
      />
    </Layout>
  );
};

export default AboutPage;

export const data = graphql`
  query ($language: String) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allContentfulAboutPage(
      sort: { fields: [createdAt], order: DESC }
      filter: { node_locale: { eq: $language } }
    ) {
      edges {
        node {
          seoSettings {
            metadataTitle
            metadataDescription
            openGraphImage {
              gatsbyImageData
              url
            }
            canonicalUrl
            robots
          }
          bannerTitle
          bannerSubtitle
          founderLetterTitle
          founderLetterBody {
            founderLetterBody
          }
          founderLetterSignature {
            gatsbyImageData(layout: FULL_WIDTH, width: 89)
          }
          missionSectionTitle
          missionSectionDescription
          missionSectionBoxes {
            title
            metric
          }
          visionSectionTitle
          visionSectionDescription
          valuesSectionTitle
          valuesSectionValues {
            name
            description {
              description
            }
          }
          teamSectionTitle
          teamSectionHeadshots {
            name
            jobTitle
            linkedIn
            headShot {
              gatsbyImageData(layout: FULL_WIDTH, width: 260)
            }
            petHeadShot {
              gatsbyImageData(layout: FULL_WIDTH, width: 260)
            }
          }
          investorSectionTitle
          offices {
            name
            address
            lat
            long
          }
          bottomCta {
            title
            subtitle
            primaryButtonId
            secondaryButtonId
            primaryButtonText
            secondaryButtonText
            primaryButtonRoute
            secondaryButtonRoute
            primaryButtonClassName
            secondaryButtonClassName
          }
          createdAt
        }
      }
    }
  }
`;
