import React from "react";
import * as styles from "./FounderLetterSection.module.scss";

const FounderLetterSection = props => {
  const { body } = props;

  return (
    <section className={styles.founderLetterSection}>
      <div className={styles.letter}>
        <span dangerouslySetInnerHTML={{ __html: body }}></span>
      </div>
    </section>
  );
};

export default FounderLetterSection;
