import cx from "classnames";
import * as _ from "lodash";
import React from "react";
import * as aboutUsStyles from "../AboutUs.module.scss";
import MetricBox from "./components/MetricBox";
import * as styles from "./OurMissionSection.module.scss";

class OurMissionSection extends React.Component {
  render() {
    return (
      <section
        className={cx(styles.ourMissionSection, aboutUsStyles.container)}
      >
        <div className={styles.missionHolder}>
          <h5>{this.props.title}</h5>
          <h2>{this.props.description}</h2>
        </div>

        {/* for desktop & table */}
        <div className={styles.metricHolder}>
          {_.map(this.props.boxes, (box, idx) => {
            if (idx === 0) {
              return (
                <MetricBox
                  purple={true}
                  title={box.title}
                  metric={box.metric}
                />
              );
            }
            if (idx === 3) {
              return (
                <MetricBox
                  purple={true}
                  title={box.title}
                  metric={box.metric}
                />
              );
            }
            return (
              <MetricBox key={idx} title={box.title} metric={box.metric} />
            );
          })}
        </div>

        {/* for mobile */}
        <div className={cx(styles.metricHolder, styles.metricHolderMobile)}>
          {_.map(this.props.boxes, (box, idx) => {
            if (idx === 0) {
              return (
                <MetricBox
                  purple={true}
                  title={box.title}
                  metric={box.metric}
                />
              );
            }
            if (idx === 2) {
              return (
                <MetricBox
                  purple={true}
                  title={box.title}
                  metric={box.metric}
                />
              );
            }
            return (
              <MetricBox key={idx} title={box.title} metric={box.metric} />
            );
          })}
        </div>
      </section>
    );
  }
}

export default OurMissionSection;
