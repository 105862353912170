import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import cx from "classnames";
import * as aboutUsStyles from "../AboutUs.module.scss";
import * as styles from "./OurInvestorsSection.module.scss";

const OurInvestorsSection = props => {
  const images = useStaticQuery(graphql`
    {
      SVAngel: file(relativePath: { eq: "investors/svangel@3x.png" }) {
        childImageSharp {
          gatsbyImageData(height: 52, layout: FIXED)
        }
      }
      S28Capital: file(relativePath: { eq: "investors/s-28@3x.png" }) {
        childImageSharp {
          gatsbyImageData(height: 27, layout: FIXED)
        }
      }
      lightspeed: file(relativePath: { eq: "investors/lightspeed@3x.png" }) {
        childImageSharp {
          gatsbyImageData(height: 29, layout: FIXED)
        }
      }
      conversionCapital: file(
        relativePath: { eq: "investors/conversion-captial@3x.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 29, layout: FIXED)
        }
      }
      foundersFund: file(
        relativePath: { eq: "investors/foundersfund@3x.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 22, layout: FIXED)
        }
      }
      nyca: file(relativePath: { eq: "investors/nyca@3x.png" }) {
        childImageSharp {
          gatsbyImageData(height: 38, layout: FIXED)
        }
      }
      cherubicVentures: file(
        relativePath: { eq: "investors/cherubic-ventures@3x.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 40, layout: FIXED)
        }
      }
      companyVentures: file(
        relativePath: { eq: "investors/companyventures@3x.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 56, layout: FIXED)
        }
      }
      VC8: file(relativePath: { eq: "investors/8-vc@3x.png" }) {
        childImageSharp {
          gatsbyImageData(height: 42, layout: FIXED)
        }
      }
      allen: file(relativePath: { eq: "investors/allen-company@3x.png" }) {
        childImageSharp {
          gatsbyImageData(height: 33, layout: FIXED)
        }
      }
    }
  `);

  return (
    <section
      className={cx(styles.ourInvestorsSection, aboutUsStyles.container)}
    >
      <h2>{props.title}</h2>
      <div className={styles.investors}>
        <span
          role="presentation"
          onClick={() => window?.open("https://svangel.com/", "_blank")}
        >
          <GatsbyImage
            image={images.SVAngel.childImageSharp.gatsbyImageData}
            className={styles.investor}
            alt="SV Angel"
          />
        </span>
        <span
          role="presentation"
          onClick={() => window?.open("https://www.s28capital.com/", "_blank")}
        >
          <GatsbyImage
            image={images.S28Capital.childImageSharp.gatsbyImageData}
            className={styles.investor}
            alt="S28 Capital"
          />
        </span>
        <span
          role="presentation"
          onClick={() => window?.open("https://lsvp.com/", "_blank")}
        >
          <GatsbyImage
            image={images.lightspeed.childImageSharp.gatsbyImageData}
            className={styles.investor}
            alt="Lightspeed"
          />
        </span>
        <span
          role="presentation"
          onClick={() =>
            window?.open("http://www.conversioncapital.com/", "_blank")
          }
        >
          <GatsbyImage
            image={images.conversionCapital.childImageSharp.gatsbyImageData}
            className={styles.investor}
            alt="Conversion Capital"
          />
        </span>
        <span
          role="presentation"
          onClick={() => window?.open("https://foundersfund.com/", "_blank")}
        >
          <GatsbyImage
            image={images.foundersFund.childImageSharp.gatsbyImageData}
            className={styles.investor}
            alt="Founders Fund"
          />
        </span>
        <span
          role="presentation"
          onClick={() => window?.open("https://www.nyca.com", "_blank")}
        >
          <GatsbyImage
            image={images.nyca.childImageSharp.gatsbyImageData}
            className={styles.investor}
            alt="NYCA"
          />
        </span>
        <span
          role="presentation"
          onClick={() => window?.open("https://cherubic.com/", "_blank")}
        >
          <GatsbyImage
            image={images.cherubicVentures.childImageSharp.gatsbyImageData}
            className={styles.investor}
            alt="Cherubic Ventures"
          />
        </span>
        <span
          role="presentation"
          onClick={() => window?.open("https://company.co/", "_blank")}
        >
          <GatsbyImage
            image={images.companyVentures.childImageSharp.gatsbyImageData}
            className={styles.investor}
            alt="Company Ventures"
          />
        </span>
        <span
          role="presentation"
          onClick={() => window?.open("http://eight.vc/", "_blank")}
        >
          <GatsbyImage
            image={images.VC8.childImageSharp.gatsbyImageData}
            className={styles.investor}
            alt="8VC"
          />
        </span>
        <span
          role="presentation"
          onClick={() =>
            window?.open(
              "https://en.wikipedia.org/wiki/Allen_%26_Company",
              "_blank"
            )
          }
        >
          <GatsbyImage
            image={images.allen.childImageSharp.gatsbyImageData}
            className={styles.investor}
            alt="Allen & Co"
          />
        </span>
      </div>
    </section>
  );
};

export default OurInvestorsSection;
