import cx from "classnames";
import GoogleMapReact from "google-map-react";
import * as _ from "lodash";
import React from "react";
import * as styles from "./MapSection.module.scss";
import { cleanAndSimpleStyle } from "./mapstyle";

interface MapSectionProps {
  offices: any;
}

const MapSection: React.FC<MapSectionProps> = props => {
  const { offices } = props;

  const [activeLocation, setActiveLocation] = React.useState(offices[0]);

  const renderMarker = (map, maps) => {
    offices?.forEach((location, idx) => {
      new maps.Marker({
        map: map,
        position: new maps.LatLng(location?.lat, location?.long),
        title: ""
      });
    });
  };

  return (
    <section className={styles.mapSection}>
      <div className={styles.map}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAPS_KEY }}
          options={{
            fullscreenControl: false,
            gestureHandling: "none",
            styles: cleanAndSimpleStyle
          }}
          center={[activeLocation?.lat, activeLocation?.long]}
          defaultZoom={14}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) => renderMarker(map, maps)}
        ></GoogleMapReact>
      </div>
      <div className={styles.locationPicker}>
        {offices?.map((location, idx) => {
          const classes =
            location?.name === activeLocation?.name
              ? cx(styles.locationItem, styles.selected)
              : styles.locationItem;
          return (
            <div
              role="presentation"
              onClick={() => setActiveLocation(location)}
              className={classes}
            >
              <h4>{location?.name}</h4>
              <p>{location?.address}</p>
            </div>
          );
        })}
      </div>
      <div className={styles.extraSpace} />

      <div className={styles.mapsList}>
        {offices?.map((location, idx) => {
          return (
            <>
              <div className={styles.mapMobile}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: process.env.GOOGLE_MAPS_KEY }}
                  options={{
                    styles: cleanAndSimpleStyle
                  }}
                  center={[activeLocation.lat, activeLocation.long]}
                  defaultZoom={14}
                  yesIWantToUseGoogleMapApiInternals={true}
                  onGoogleApiLoaded={({ map, maps }) => {
                    new maps.Marker({
                      map: map,
                      position: new maps.LatLng(121.456241, location?.long),
                      title: ""
                    });
                  }}
                ></GoogleMapReact>
              </div>
              <h4>{location?.name}</h4>
              <p>{location?.address}</p>
            </>
          );
        })}
      </div>
    </section>
  );
};

export default MapSection;
