import cx from "classnames";
import React from "react";
import NavigationBarSpacer from "../../NavigationBarSpacer";
import * as styles from "./Banner.module.scss";

export default class Banner extends React.Component {
  render() {
    const classes = this.props.gradientOverlay
      ? cx(styles.banner, styles.gradientOverlay)
      : styles.banner;
    return (
      <div className={classes} style={this.props.style}>
        <NavigationBarSpacer />
        <h1>{this.props.title}</h1>
        <h4>{this.props.subtitle}</h4>
      </div>
    );
  }
}
