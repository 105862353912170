import React from "react";
import cx from "classnames";

import * as styles from "./MetricBox.module.scss";

class MetricBox extends React.Component {
  render() {
    const { purple } = this.props;
    const className = purple
      ? cx(styles.metricBox, styles.purple)
      : styles.metricBox;
    return (
      <div className={className}>
        <div className={styles.title}>{this.props.title}</div>
        <div className={styles.metric}>{this.props.metric}</div>
      </div>
    );
  }
}

export default MetricBox;
